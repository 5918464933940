import React, { Component } from 'react'
import Layout from '../components/Layout'
import Img from "gatsby-image"
import { Link } from 'gatsby';

class IndexPage extends Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
      .filter(edge => !! edge.node.frontmatter.date)
      .map(edge => {
        const post = edge.node.frontmatter;
        console.log(edge.node.fields.slug);
        return (
          <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key="thead">
            <Link to={edge.node.fields.slug}>
              <div className="card h-100">
                <Img fluid={post.thumbnail.childImageSharp.fluid} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">{post.title}</h5>
                    <p className="card-text description">{post.description}</p>
                    <p className="card-text"><small className="text-muted">Posted on {post.date}</small></p>
                  </div>
              </div>
            </Link>
          </div>
        )
      })
    return (
      <Layout>
        <section className="mt-2">
          <div className="text-center">
            <h1>Metanomalies</h1>
            <p>Metanomalies is a community of communities self-reflecting <br /> on the values and process of building
              communities.</p>
          </div>
        </section>

        <section style={{paddingTop:"70px"}}>
          <div className="container">
            <div className="row">
              {posts}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query($path: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { rootPage: {eq: $path }}}
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt(pruneLength: 25)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`